<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form v-model="isFormValid">
          <v-container
            v-if="hasPermission('View Role')"
            id="UpdateRole"
            class="munjz-bg-grey-dark"
            style="max-width: 800px"
            tag="section"
          >
            <v-row class="ma-0">
              <v-row class="ma-0 munjz-bg-grey">
                <v-col cols="10" sm="8" class="d-flex align-center me-auto">
                  <span
                    >Roles & Permissions
                    <v-icon>mdi-chevron-right</v-icon></span
                  >
                  <span class="text-sm-h2 text-h3">Roles Details</span>
                </v-col>
                <v-col cols="2" sm="1" class="d-flex justify-end px-0">
                  <v-btn
                    v-if="hasPermission('Edit Role')"
                    small
                    class="blue-grey lighten-4"
                    fab
                    text
                    :to="`/edit-role/${this.$route.params.id}`"
                    icon
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2" sm="1" class="d-flex justify-end px-0">
                  <v-btn
                    small
                    class="blue-grey lighten-4"
                    fab
                    text
                    icon
                    to="/roles"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-col cols="12" class="px-0">
                <v-text-field
                  v-model="role_name"
                  label="Role Name"
                  required
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-textarea
                  v-model="role_description"
                  label="Role Description"
                  readonly
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-autocomplete
                  v-model="selected_teams"
                  :items="teams"
                  required
                  item-text="name"
                  item-value="id"
                  label="Selected team member"
                  outlined
                  readonly
                  hide-details
                  dense
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"><h3 class="mt-10">Permissions</h3></v-col>
              <v-col cols="12" class="">
                <v-row
                  class="ma-0"
                  justify="center"
                  v-for="(permission, idx) in permissions"
                  :key="idx"
                >
                  <v-col
                    v-if="shouldDisplayPermissionGroup(permission.group, idx)"
                    cols="12"
                    class="pa-0 mt-10 mb-2"
                  >
                    <div class="d-flex  align-center">
                      <v-icon small class="me-1" color="primary"
                        >mdi-checkbox-blank</v-icon
                      >
                      <h4 class="ma-0">{{ permission.group }}</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-list>
                      <v-list-item-group class="rounded-lg  d-flex align-items">
                        <v-list-item class=" rounded-lg " disabled>
                          <v-list-item-icon class="mx-0 ">
                            <v-icon small color="primary">mdi-check</v-icon>
                          </v-list-item-icon>

                          <v-list-item-title
                            class="primary--text "
                            v-text="permission.name"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>

              <v-snackbar
                v-model="snackbar.show"
                centered
                :color="snackbar.color"
              >
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      permissionsGroupList: ["none"],
      deleteRoleAction: null,
      deleteDialog: false,
      editEnabled: false,
      snackbar: {
        text: "",
        color: "",
        show: false
      },
      isFormValid: false,
      isLoading: false,
      role: null,
      permissions: null,
      role_id: 0,
      role_name: "",
      role_description: "",
      partner_permissions: [],
      selected_permissions: [],
      teams: [],
      selected_teams: [],
      user_id: localStorage.getItem("userid"),
      token: localStorage.getItem("token")
    };
  },
  mounted() {
    this.getAdminPermissions();
    this.getPermissions();
    this.getTeams();
    this.getRoleDetail();
  },

  methods: {
    showSnackbar(message, color) {
      this.snackbar.show = true;
      this.snackbar.text = message;
      this.snackbar.color = color;
    },
    getRoleDetail() {
      axios
        .get("/api/v3/b2b/roles/detail", {
          headers: { "X-Authorization": this.token },
          params: {
            role_id: this.$route.params.id
          }
        })
        .then(res => {
          this.role = res.data.partner_role;
          this.permissions = this.role.permissions;
          this.permissionsGroupList = this.permissions.map(permission => {
            return permission.group;
          });

          this.role_name = this.role.name;
          this.role_id = this.role.id;
          this.role_description = this.role.description;
          this.permissions.forEach(permission => {
            this.selected_permissions.push(permission.id);
          });

          this.role.users.forEach(user => {
            this.selected_teams.push(user.id);
          });
        })
        .catch(err => console.log(err));
    },
    getPermissions() {
      //  alert("user"+this.user_id)
      axios
        .get("/api/v3/b2b/permissions/list", {
          headers: { "X-Authorization": this.token }
        })
        .then(res => {
          this.partner_permissions = res.data.permissions;
        })
        .catch(err => console.log(err));
    },
    getTeams() {
      axios
        .get(
          `/api/v3/get-partner-admins?user_id=${localStorage.getItem(
            "userid"
          )}`,
          {
            headers: { "X-Authorization": this.token }
          }
        )
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          if (err.response.data.status_code) {
            // this.$router.push("/");
          }
        })
        .finally(() => (this.loading = false));
    },
    UpdateRole() {
      axios
        .post(
          "/api/v3/b2b/roles/update",
          {
            role_id: this.role_id,
            role_name: this.role_name,
            role_description: this.role_description,
            selected_permissions: this.selected_permissions,
            selected_teams: this.selected_teams
          },
          {
            headers: { "X-Authorization": this.token }
          }
        )
        .then(response => {
          if (response.data.status == 200) {
            this.teams.push(response.data.new_admin);
            this.showSnackbar(response.data.message, "green");
            this.$router.push({
              path: `/home/RolesList`
            });
          } else {
            this.errors = response.data.errors;
            this.showSnackbar(response.data.message, "red");
          }
        })
        .catch(error => {
          if (error.response.data.status_code) {
            // this.$router.push("/");
          }
          this.showSnackbar("Something went wrong", "red");
        });
    },
    shouldDisplayPermissionGroup(group, idx) {
      return this.permissionsGroupList.indexOf(group) === idx;
    }
  },
  computed: {}
};
</script>
